<template lang="pug">
  include ../plugins/pug/btn

  header.header.bg-black2.color-white
    div.container
      div.header__body
        div.header__main
          a(href="/").header__logo.logo.transition
            img(
              :src="Logo"
              alt="logo"
            )
          nav(
            :class="{ 'active': isMenuOpen }"
            @click="isMenuOpen = !isMenuOpen; openMenu()"
          ).navigation
            ul
              li(v-if="isMobile")
                router-link(
                  to="/"
                  :class="{ active: isActive('/') }"
                ).link Home
              li
                router-link(
                  to="/services"
                  :class="{ active: isActive('/services') }"
                ).link Services
              li
                router-link(
                  to="/about"
                  :class="{ active: isActive('/about') }"
                ).link About us
              li
                router-link(
                  to="/cases"
                  :class="{ active: isActive('/cases') }"
                ).link Cases
              li
                router-link(
                  to="/vacancies"
                  :class="{ active: isActive('/vacancies') }"
                ).link Vacancies
              li
                router-link(
                  to="/contacts"
                  :class="{ active: isActive('/contacts') }"
                ).link Contact
        //div.header__switch
        //  img(src="@/assets/icons/switch-icon.png" alt="switch")
        button(
          type="button"
          :class="{ 'active': isMenuOpen }"
          @click="isMenuOpen = !isMenuOpen; openMenu()"
        ).hamburger
          span
          span
          span
</template>

<script>
// images
import Logo from '@/assets/icons/logo.svg'
export default {
  name: 'HeaderPiece',
  data () {
    return {
      Logo,
      isMobile: false,
      isMenuOpen: false
    }
  },
  methods: {
    openMenu () {
      if (document.documentElement.clientWidth <= 1199) this.isMobile = true
    },
    isActive (route) {
      return this.$route.path === route
    }
  }
}
</script>
